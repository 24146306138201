<template>
  <div class="page page--spartan-plus-perks">
    <dir class="back-button" @click="$router.push({ name: 'benefits' })">
      <i class="material-icons">keyboard_arrow_left</i>
      <span>{{ $t("back") }}</span>
    </dir>
    <img
      class="benefits-logo"
      :src="require('@/assets/spartan_plus_benefits_logo.svg')"
      alt="spartan+ member perks"
    />
    <p class="description">
      {{ $t("benefits_description") }}
    </p>
    <!-- <div class="page-header">
      <div class="header-row">
        <span class="header-title">{{ $t("outfits_title") }}</span>

        <mem-button
          class="header-cta"
          btn-type="tertiary-dark"
          @click="onOutfitButtonClick"
        >
          {{ $t("see_all") }}
        </mem-button>
      </div>
    </div>

    <div class="outfit-products" v-if="outfitsList.length">
      <product-carousel :items="outfitsList" />
    </div> -->

    <!-- Member deals -->
    <!-- <div class="member-deals-section">
      <div class="section-title">member deals</div>
      <div class="section-description">
        Beyond your Spartan membership benefits we are scouring the earth for
        the best offers to bring you. As a member you get access to deals in the
        Spartan+ network. Click on an offer below for how to use it.
      </div>
      <div class="section-items-grid">
        <member-deals-card
          v-for="(item, index) in memberDealsList"
          :key="index"
          :card-data="item"
        ></member-deals-card>
      </div>
    </div> -->

    <!-- Benefits -->
    <div class="page-header">
      <div class="header-row">
        <span class="header-title">{{ $t("benefits_title") }}</span>
      </div>
    </div>

    <div class="benefits-grid">
      <benefits-card
        v-for="(item, index) in memberBenefitsList"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import localizeResolver from "@/common/mixins/localizeResolver";

// import ProductCarousel from "@/components/shop/ProductCarousel";

import { memberDealsList, memberBenefitsList } from "@/lists";

export default {
  name: "SpartanPlusPerksView",
  components: {
    "benefits-card": () => import("./components/CardBenefitItem.vue"),
    // "mem-button": () => import("@/components/base/BaseButton.vue"),
    // "member-deals-card": () => import("./components/CardMemberDeals.vue"),
    // ProductCarousel,
  },
  data: () => ({
    memberDealsList,
    memberBenefitsList,
  }),
  mixins: [localizeResolver],
  computed: {
    ...mapGetters([
      "userData",
      "userSubscription",
      "isLoadedUserData",
      "outfitsList",
    ]),
    isAvailablePage() {
      if (!this.userData) return false;

      return (
        ["US"].includes(this.userCountry) &&
        this.userSubscription &&
        this.userSubscription.status === "active"
      );
    },
  },
  methods: {
    ...mapActions(["loadOutfits"]),
    onOutfitButtonClick() {
      window.open("//www.spartan.com/collections/spartan-plus", "_blank");
    },
    // onDealsButtonClick() {
    //   if (this.userData.endurance_zone_url) {
    //     window.open(this.userData.endurance_zone_url, "_blank");
    //   }
    // },
  },
  watch: {
    isLoadedUserData: {
      immediate: true,
      handler(val) {
        if (val) {
          console.log("isLoadedUserData");
          if (!this.isAvailablePage) {
            window.location.replace(
              "https://www.spartan.com/pages/spartan-plus-membership"
            );
          } else {
            this.loadOutfits();
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.page--spartan-plus-perks {
  max-width: min(670px, 100vw);
  width: 100%;
  margin-right: auto;
  padding-bottom: 150px;
  position: relative;
  .back-button {
    position: absolute;
    top: 20px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 700;
    height: 44px;
    @include cursorPointer;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }

  .benefits-logo {
    display: block;
    max-width: 400px;
    user-select: none;
    margin: 40px auto 0;

    @media screen and (max-width: 499px) {
      max-width: 228px;
      margin-top: 75px;
    }
  }

  .description {
    max-width: 500px;
    text-align: center;
    margin: 43px auto 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 152.5%;

    @media screen and (max-width: 499px) {
      margin: 36px 20px 0;
      text-align: left;
    }
  }

  .page-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 62px;
    margin-bottom: 27px;
    @media screen and (max-width: 499px) {
      margin-left: 20px;
      margin-right: 20px;
      text-align: left;
    }
    .header-row {
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 499px) {
        align-items: flex-end;
      }

      .header-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        text-transform: capitalize;
        color: #ffffff;
        @media screen and (max-width: 499px) {
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
        }
      }
      .header-cta {
        width: 119px;
        height: 36px;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
      }

      .header-description {
        font-weight: 500;
        font-size: 14px;
        line-height: 152.5%;
      }

      & + .header-row {
        margin-top: 35px;
      }
    }
  }
  .member-deals-section {
    padding-top: 35px;
    @media screen and (max-width: $mobile) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .section-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-transform: capitalize;
      margin-bottom: 35px;
    }
    .section-description {
      font-size: 14px;
      line-height: 21px;
    }
    .section-items-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-top: 52px;

      @media screen and (max-width: $mobile) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;
      }
    }
  }

  .benefits-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media screen and (max-width: 499px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
      margin-top: 20px;
    }
    .benefit-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #121212;
      padding: 0 15px 25px;

      @media screen and (max-width: 499px) {
        padding: 0 15px 25px;
      }

      .benefit-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 132px;
        width: 100%;

        .benefit-icon {
          display: block;
          // max-height: 95px;
          max-width: 100%;
          object-fit: contain;
        }
      }

      .benefit-title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 139%;
        text-align: center;
        color: white;
        margin-top: 24px;
      }

      .benefit-description {
        margin-top: 8px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 152.5%;
        color: white;
        text-align: center;
      }

      &--additional {
        background: transparent;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 0;
        padding-bottom: 0;

        @media screen and (max-width: 499px) {
          padding: 0;
        }

        .benefit-title {
          margin-top: 5px;
        }

        .benefit-list {
          list-style: none;
          padding-left: 0;
          margin-top: 12px;

          .benefit-list-item {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 139%;
            text-transform: capitalize;

            & + .benefit-list-item {
              margin-top: 12px;
            }
          }
        }

        .show-more {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          color: white;
          text-decoration: none;
          margin-top: auto;

          &:active,
          &:visited {
            color: white;
            text-decoration: none;
          }

          @media screen and (max-width: 499px) {
            margin-top: 39px;
          }
        }
      }
    }
  }

  .outfit-products {
    @media screen and (max-width: 499px) {
      margin-top: 32px;
      margin-left: 16px;
    }
  }
}
</style>